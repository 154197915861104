<template>
  <v-container class="main-container" >
    <v-row no-gutters style="margin-top: 80px; margin-bottom: 60px">
      <h1>Oh nein, du scheinst noch außerhalb unseres Liefergebietes zu liegen!</h1>
    </v-row>

    <v-row justify="center" class="mb-15">
      <img :src="scooterImg" alt="">
    </v-row>

    <v-row no-gutters justify="center" v-if="!voted">
      <h2 class="mb-4" align="center">Wir möchten unser Liefergebiet erweitern – aber wo anfangen?</h2>
      <p class="mb-8" align="center">Vote jetzt für deine Nachbarschaft und hol McDelivery® zu dir!</p> <br>
      <v-col cols="12" align="center">
        <button class="mdButton d-block" @click="submitVote" style="margin-bottom: 10px;">Voten</button>
      </v-col>
    </v-row>

    <v-row v-else style="margin-bottom: 30px;">
      <v-col cols="12" align="center">
        <h2 class="mb-4" align="center"> Danke fürs Abstimmen! Die gerade von dir eingegebene Postleitzahl haben wir registriert. Und wer weiß, vielleicht klingeln wir auch bald bei dir.</h2>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" align="center">
        <button class="mdButton d-block" @click="redirectBackToHome" style="margin-bottom: 150px;">Zurück zur Startseite</button>
      </v-col>
    </v-row>


    <TeaserImage :imgSrc="teaser1img" :withContentBox="true" style="margin-bottom: 100px;">
      <h2 class="mb-2">Finde das nächstgelegene Restaurant</h2>
      <p class="mb-4">Wir liefern leider nicht zu dir, freuen uns aber trotzdem auf deinen Besuch!</p> <br>
      <a href="https://www.mcdonalds.com/de/de-de/restaurant-suche.html" class="mdButton">Zum Restaurant-Finder</a>
    </TeaserImage>

    <TeaserImage :imgSrc="teaser2img" :withContentBox="true">
      <h2 class="mb-2">Schau dir auch unsere neuesten Deals an!</h2>
      <p class="mb-4">Erfahre alles über unsere aktuellesten Angebote und Aktionen.</p> <br>
      <a href="https://www.mcdonalds.com/de/de-de.html" class="mdButton">Jetzt anschauen</a>
    </TeaserImage>
  </v-container>
</template>

<script>
import TeaserImage from "@/components/TeaserImage";

import scooter from "@/assets/images/scooter.png";
import noSypply1 from "@/assets/images/Storefinder.png";
import noSypply2 from "@/assets/images/noSupply2old.png";
import axios from "axios";
import router from "@/router";



  export default {
    name: 'VotePage',
    components: {
      TeaserImage
    },
    data: () => ({
      teaser1img: noSypply1,
      teaser2img: noSypply2,
      scooterImg: scooter,
      zipCodeForVoting: '',
      voted: false
    }),
    created(){
      this.scrollToTop()
    },
    mounted(){
      this.storeZipCodeAndClearUrl()
    },
    methods: {
      scrollToTop() {
        window.scrollTo(0,0);
      },
      storeZipCodeAndClearUrl () {
        if (this.$route.query.plz) {
          this.zipCodeForVoting = this.$route.query.plz
          this.$router.replace({'query': null});
        } else {
          router.push({path: '/'})
        }
      },
      matomoTrackingEventVote() {
        window._paq.push(['trackEvent', 'Conversions', 'Vote for Service', this.zipCodeForVoting]);
      },
      submitVote() {
        this.matomoTrackingEventVote();
        axios.post(process.env.VUE_APP_API_URL + "vote", this.zipCodeForVoting,
        {
              headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
              }
            })
            .then(response => {
              // console.log(this.zipCodeForVoting)
              console.log(response.data)
              this.voted = true;

            })
            .catch(error => {
              this.errorMessage = error.message;
              console.error("There was an error!", error);
            });
      },
      redirectBackToHome(){
        router.push({name: 'Home'})
      }
    },
    beforeDestroy () {
    }
  }
</script>


<style scoped>
.main-container {
  max-width: 1168px;
}

</style>
