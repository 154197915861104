<template>
  <v-row no-gutters class="teaser-image" justify="center"
         :class="{ mobile:$vuetify.breakpoint.smAndDown, noBorder: ($vuetify.breakpoint.smAndDown && noBorderOnMobile)}">
    <v-col cols="12" align="center" justify="center" class="d-flex flex-column">
      <img class="teaser-img" :src="imageSrc" :alt="altTag"/>
      <div v-if='withContentBox' class="contentBox " :class="{mediumSize: $vuetify.breakpoint.md}">
        <div class="contentBoxInner pt-6 pr-8 pb-6 pl-8">
          <slot></slot>
        </div>
      </div>
      <div v-if="showLegalText" class="legal-text">*Gewinnspiel vom 22.08. - 04.09.2024. Nur solange der Vorrat reicht. Weitere Informationen zum Gewinnspiel in der Coca-Cola App oder auf der Coca-Cola <a target="_blank" href="https://www.coca-cola.com/de/de/legal/mcd-wristband">Webseite</a>.</div>  
    </v-col>
    <v-col cols="12" align="center" v-if="hasAdditionalCTA">
      <a href="https://www.mcdonalds.com/de/de-de/kampagnen/coke-and-win.html" class="mdButton mt-3 mt-md-6">Mehr Info</a>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'TeaserImage',
  props:['imgSrc', 'mobileImageSrc', 'withContentBox', 'showLegalText', 'altTag', 'noBorderOnMobile', 'hasAdditionalCTA'],
  data: () => ({}),
  computed: {
    imageSrc () {
      if (this.imgSrc && this.mobileImageSrc) {
        return this.$vuetify.breakpoint.xs ? this.mobileImageSrc : this.imgSrc
      } else if (this.imgSrc && !this.mobileImageSrc) {
        return this.imgSrc
      } else {
        return ""
      }
    }
  },
  methods: {
    printCurrentYear(){
      return new Date().getFullYear()
    }
  }
}

</script>


<style lang="scss" scoped>
.teaser-image {
  width: 100%;
  position: relative;
}

.teaser-img{
  max-width: 100%;
  width: 100%;
}

.contentBox {
  max-width: 436px;
  position: absolute;
  //display: block;
  top: 65px;
  left: 50px;
  display: flex;
  align-items: center;

  &.mediumSize {
    top: 0;
    bottom: 0;
  }
}

.contentBoxInner {
  background: #FFFFFF;

}

/*MOBILE CSS*/
.teaser-image.mobile {
  border: 1px solid #CECECE;
  border-radius: 0 0 4px 4px;

  .contentBox {
    max-width: none;
    position: static;
  }

}

.teaser-image.mobile.noBorder {
  border: 0;
}

//REPONSIVE LEGAL TEXT CSS
.teaser-image {
  position: relative;

  .col {
    position: relative;
  }

  .legal-text {
    text-align: left;
    position: absolute;
    bottom: 3px;
    left: 0px;
    font-size: 10px;
    line-height: 1;
    color: black;
    width: 100%;
    padding: 0px 10px;
    z-index: 200;
    //max-width: 800px;
  }

  &.mobile .legal-text {
    position: relative;
    padding: 10px 10px 0px;
    bottom: 0px;
  }

  &.mobile {
    //margin-bottom: 80px !important;
  }

  //&.mobile .legal-text {
  //  display: flex;
  //  padding: 4px 13px;
  //  width: 100%;
  //  line-height: 1.2;
  //  color: black;
  //  z-index: 10;
  //  font-size: 11px;
  //  top: calc(100% - 1px);
  //  //background: rgb(86,11,12);
  //  //background: linear-gradient(90deg, rgba(86,11,12,1) 0%, rgba(79,4,4,1) 70%, rgba(48,1,0,1) 100%);
  //}
}

</style>
