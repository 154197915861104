<template>
  <div class="box-image-text">
    <v-row justify="center">
      <v-col v-for="(boxItem,i) in boxContent" class="pa-2" :key="i" cols="12" :md="dynamicCols" sm="6">
        <div class="box-content-wrapper" :class="$vuetify.breakpoint.xs ? 'mobile' : ''">
          <img class="box-image mb-14" :src="boxItem.image" :alt="boxItem.altTag">
          <!-- header-->
          <h2 class="mb-4" v-html="boxItem.header"></h2>

          <!-- text-->
          <p v-if="boxItem.text" v-html="boxItem.text" class="mb-9"></p>

          <!-- list-->
          <ul v-if="boxItem.list" class="mb-4 pr-3" :class="$vuetify.breakpoint.xs ? 'pl-8' : ''">
            <li v-for="(listItem, i) in boxItem.list" :key="i" v-html="listItem"></li>
          </ul>
        </div>
      </v-col>
    </v-row>

  </div>
</template>

<script>

export default {
  name: 'BoxesImageText',
  props: ['boxContent'],
  data: () => ({
  }),
  methods:{},
  computed: {
    dynamicCols(){
      if (this.boxContent.length <= 2) {
        return 6
      } else if (this.boxContent.length <= 3) {
        return 4
      } else {
        return 3
      }
    }
  }
}
</script>


<style lang="scss" scoped>
.box-image-text {
  margin-bottom: 65px;
}
.main-teaser {
  width: 100%;
}

.box-image {
  width: 100%;
}

.teaser-img{
  max-width: 100%;
}

.box-content-wrapper.mobile {
  border: 1px solid #CECECE;
  border-radius: 0 0 4px 4px;

  h2, p {
    padding-left: 10px;
    padding-right: 10px;
  }
}


</style>
