import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import MainPage from "@/views/MainPage";
import VotePage from "@/views/VotePage";
// import CokeAndWin from "@/views/CokeAndWin";
// import CokeAndWinFormSuccess from "@/views/CokeAndWinFormSuccess";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: MainPage
  },
  {
    path: '/vote',
    name: 'Vote',
    component: VotePage,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/VotePage.vue')
  },
  // {
  //   path: '/cokeandwin',
  //   name: 'CokeAndWin',
  //   component: CokeAndWin,
  // },
  // {
  //   path: '/cokeandwin/success',
  //   name: 'CokeAndWinFormSuccess',
  //   component: CokeAndWinFormSuccess,
  // },
  {
    path: '*', // Wildcard-Pfad
    redirect: '/' // Weiterleitung auf die Startseite
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
