<template>
  <v-row>
    <v-col justify="center" class="accordion-component" :class="$vuetify.breakpoint.mdAndDown ? 'mobile' : ''" >
      <v-expansion-panels accordion>
        <v-expansion-panel
            v-for="(faq,i) in accordionContent"
            :key="i"
        >
          <v-expansion-panel-header class="heading" @click="matomoTrackingEventFaqClicked(faq.header)"><span v-html="faq.header"></span></v-expansion-panel-header>
          <v-expansion-panel-content >
            <p v-html="faq.content" style="max-width: 1000px;">
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>



<script>
export default {
  name: 'AccordionComponent',
  props: ['accordionContent'],
  data: () => ({
  }),
  methods: {
    matomoTrackingEventFaqClicked(question) {
      window._paq.push(['trackEvent', 'Website Modules', 'FAQ- Accordion', question]);
    }
  }
}
</script>


<style lang="scss" scoped>
.accordion-component {
  //border-top: 1px solid #CECECE;
  //border-bottom: 1px solid #CECECE;

  .heading {
    font-family: 'Speedee';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #292929;
    padding-left: 0;
    padding-right: 0;
  }

  &.mobile {
    //margin-right: 28px;
    //margin-left: 28px;
    .heading {
      font-size: 18px;
    }
  }

}


</style>

<style>
.v-expansion-panel::before {
  box-shadow: none !important;
  /*border: 0 !important;*/
}

.v-expansion-panel::after {
  /*box-shadow: none !important;*/
  /*border: 0 !important;*/
  border-color: #CECECE !important;
}
</style>


<style>
.v-expansion-panel-content__wrap {
  padding-left: 0;
}
.accordion-component .v-expansion-panel:first-child {
  border-top: 1px solid #CECECE;
}

.accordion-component .v-expansion-panel:last-child {
  border-bottom: 1px solid #CECECE;
}






</style>
