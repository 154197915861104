var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"main-container"},[_c('v-row',[_c('v-col',{staticClass:"mb-5",attrs:{"no-gutters":"","justify":"center"}},[_c('h1',[_vm._v("Dein McDelivery®")])])],1),_c('TeaserImage',{staticClass:"teaser-image",style:(_vm.$vuetify.breakpoint.smAndDown ? 'mobile' : ''),attrs:{"show-legal-text":false,"imgSrc":_vm.mainTeaserImgDesktop,"mobileImageSrc":_vm.mainTeaserImgMobile || _vm.mainTeaserImgDesktop,"altTag":'McDelivery Titelbild mit Burger und Pommes',"noBorderOnMobile":true,"hasAdditionalCTA":false}}),_c('v-row',{staticClass:"info-header-1",class:_vm.$vuetify.breakpoint.smAndDown ? 'mobile' : '',attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mb-0",attrs:{"cols":"12"}},[_c('h3',[_vm._v(" McDonald's Zuhause! Jetzt Burger, Pommes und Co. liefern lassen! ")]),_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("Bitte Mindestbestellwert beachten. ")])])],1),_c('SearchComponent',{ref:"search",staticClass:"mb-15",attrs:{"id":"search"},on:{"legalLineDataAvailable":_vm.onLegalLineDataAvailable,"noSuppliersFound":function($event){return _vm.$emit('noSuppliersFound')}}}),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{style:(_vm.$vuetify.breakpoint.smAndDown
          ? 'margin-bottom: 80px'
          : 'margin-bottom: 100px'),attrs:{"cols":"12","align":"left"}},[_c('h2',{staticClass:"mb-8"},[_vm._v("So funktioniert McDelivery®")]),_c('p',{staticClass:"mb-0"},[_vm._v(" So einfach kannst du dir deine Bestellung an die Haustüre liefern lassen. ")])])],1),_c('BoxesImageText',{attrs:{"boxContent":_vm.instructionStepsBoxes}}),_c('CarouselSlider',{style:(_vm.$vuetify.breakpoint.smAndUp
        ? 'margin-bottom: 100px'
        : 'margin-bottom: 30px')}),_c('BoxesImageText',{attrs:{"boxContent":_vm.bottomBoxes}}),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticStyle:{"margin-bottom":"110px"},attrs:{"cols":"12"}},[_c('h2',{staticClass:"mb-8"},[_vm._v("Hunger bekommen?")]),_c('p',{staticClass:"mb-8"},[_vm._v("Finde heraus, ob wir auch in dein Gebiet liefern!")]),_c('br'),_c('button',{staticClass:"mdButton",on:{"click":_vm.scrollToSearch}},[_vm._v("Lass checken")])])],1),_c('ImageAndTextRow',{style:(_vm.$vuetify.breakpoint.smAndDown
        ? 'margin-bottom: 70px;'
        : 'margin-bottom: 170px;')}),_c('div',{attrs:{"id":"aloha"}}),_c('v-row',{attrs:{"no-guters":""}},[_c('v-col',{staticStyle:{"margin-bottom":"40px"},attrs:{"cols":"12"}},[_c('h2',{staticClass:"mb-8",staticStyle:{"font-size":"36px","line-height":"46px"}},[_vm._v(" Häufig gestellte Fragen ")]),_c('p',[_vm._v("Hier findest du Hilfe")]),_c('br')])],1),_c('AccordionComponent',{style:(_vm.$vuetify.breakpoint.smAndDown
        ? 'margin-bottom: 40px;'
        : 'margin-bottom: 80px;'),attrs:{"accordion-content":_vm.faqsMainPage}}),_c('v-col',{staticClass:"legal-lines",attrs:{"cols":"12"}},[_c('span',[_vm._v("Coca-Cola ist eine eingetragene Schutzmarke der The Coca-Cola Company. © "+_vm._s(_vm.printCurrentYear())+" McDonald’s")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }